import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import Axios from "axios";
import LoadingMask from "react-loadingmask";
import "react-loadingmask/dist/react-loadingmask.css";
import { api, header_token } from "../../config";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import swal from "sweetalert";

import { RiderDetailHead } from "./RiderDetailHead";
import { ExportRiderService } from "./ExportRiderService";

export const RiderDetailService = () => {
  const { id } = useParams();
  const checkAllRef = useRef(null);

  const [loading, setloading] = useState(false);

  const [data, setdata] = useState([]);
  const [current_page, setcurrent_page] = useState(1);
  const [page_count, setpage_count] = useState(0);
  const [limit] = useState(10);
  const [total_count, settotal_count] = useState(0);

  const [filter_order, setfilter_order] = useState("");

  const [export_loading, setexport_loading] = useState(false);
  const [export_data, setexport_data] = useState([]);
  const [trigger, settrigger] = useState(false);

  const exportexcel = async () => {
    setexport_loading(true);

    try {
      let send_data = {
        rider_id: id,
        end_date: "",
        order_no: filter_order,
        start_date: "",
        service: ["service", "food", "car"],
        // status: [],
      };

      let { data } = await Axios.post(
        `${api}/branch/order/filter?page=${1}&limit=${999999999}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      let _export = [];
      if (data.orders !== null) {
        data.orders.forEach((value, index) => {
          console.log(value);

          let _status;

          switch (value.status) {
            case 0:
              _status = "ยกเลิก";
              break;
            case 1:
              _status = "ออเดอร์ใหม่";
              break;
            case 4:
              _status = "กำลังจัดส่ง";
              break;
            case 5:
              _status = "ส่งสำเร็จ";
              break;
            case 6:
              _status = "ส่งไม่สำเร็จ";
              break;

            default:
              break;
          }

          if (value.type === 0) {
            _status = "ออเดอร์ล่วงหน้า";
          }

          _export.push({
            order_no: value.order_no,
            food_cost: value.food_cost,
            shipping_cost: value.shipping_cost,
            created_at: moment(value.created_at).format("DD/MM/YYYY"),
            status: _status,
          });
        });
        setexport_data(_export);
      }
      // console.log("_export", _export);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }

    setexport_loading(false);
  };

  const getData = async () => {
    setloading(true);
    try {
      let send_data = {
        rider_id: id,
        end_date: "",
        order_no: filter_order,
        start_date: "",
        service: ["service", "food", "car"],
      };

      let { data } = await Axios.post(
        `${api}/branch/order/filter?page=${current_page}&limit=${limit}&sort=${-1}&sort_by=${"created_at"}`,
        send_data,
        header_token
      );

      // console.log("data", data);
      if (data.orders !== null) {
        let cal_page_count = Math.ceil(data.meta.total_count / limit);
        setpage_count(cal_page_count);
        settotal_count(data.meta.total_count);

        setdata(data.orders);
      }
    } catch (error) {
      console.log(error);
      swal(`${error.response.data.message}`, {
        icon: "error",
        button: false,
      });
    }
    setloading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setcurrent_page(1);
    getData();
  }, [filter_order]);

  useEffect(() => {
    getData();
  }, [current_page]);

  return (
    <div>
      <div className="dashboard-header d-flex flex-column grid-margin">
        <div className="d-flex align-items-center justify-content-between flex-wrap ">
          <div className="d-flex align-items-center">
            <h4 className="mb-0 font-weight-bold mr-2">ประวัติพนักงาน</h4>
            <div className="d-none d-md-flex mt-1">
              <p className="text-muted mb-0 tx-13 cursor-pointer">หน้าแรก</p>
              <i className="mdi mdi-chevron-right text-muted"></i>
              <p className="text-muted mb-0 tx-13 cursor-pointer">
                พนักงานส่งทั้งหมด
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <RiderDetailHead />
              <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/history/${id}`}
                  >
                    ประวัติพนักงาน
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/service/${id}`}
                  >
                    ประวัติการบริการ
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/credit/${id}`}
                  >
                    ประวัติการการเติมเครดิต
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={"nav-link "}
                    activeClassName="active"
                    to={`/rider/withdraw/${id}`}
                  >
                    ประวัติการการถอนเงิน
                  </NavLink>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div className="row">
                  <div className="col">
                    <h4 className="mt-3">ทั้งหมด {total_count} รายการ</h4>
                  </div>

                  <div className="form-row align-items-center mb-2 mr-2 ">
                    <div className=" mr-2" style={{ width: 200 }}>
                      <span className="tx-13 text-muted">ค้นหา</span>
                      <input
                        type="text"
                        className="form-control- global_filter"
                        id="global_filter"
                        placeholder="Order No."
                        value={filter_order}
                        onChange={(e) => setfilter_order(e.target.value)}
                      />
                    </div>

                    <div className="col ml-1 ">
                      <div className="row">
                        <div style={{ width: 120 }} className="mr-2">
                          <span className="tx-13 text-muted">ดำเนินการ</span>
                          <Select
                            value={""}
                            onChange={(e) => {
                              // setfilter_type(e);
                            }}
                            options={[{ value: "ex", label: "Export" }]}
                            placeholder={"เลือก.."}
                          />
                        </div>

                        <div className="ml-auto ">
                          <div style={{ maxWidth: 130 }}>
                            <button
                              type="button"
                              className="btn btn-primary mr-2"
                              style={{ marginTop: 23 }}
                              onClick={async () => {
                                if (!trigger && !export_loading) {
                                  await exportexcel();
                                  await settrigger(true);
                                }
                              }}
                            >
                              {export_loading ? (
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : null}
                              Export
                            </button>
                            <ExportRiderService
                              data={export_data}
                              trigger={trigger}
                              settrigger={settrigger}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <LoadingMask loading={loading} text={"loading..."}>
                  <div style={{ minHeight: 500 }}>
                    <table id="dataTable" className="table">
                      <thead>
                        <tr>
                          <th width="30">
                            <input
                              type="checkbox"
                              className="selectAll "
                              name="selectAll"
                              defaultChecked={false}
                              ref={checkAllRef}
                              onClick={(e) => {
                                // console.log(checkAllRef.current.checked);
                                let tmp = [...data];
                                let _tmp = [];
                                tmp.forEach((value, idx) => {
                                  value.checked = checkAllRef.current.checked;
                                  _tmp.push(value);
                                });
                                setdata(_tmp);
                                // console.log(data);
                              }}
                            />
                          </th>
                          <th>Order No</th>
                          {/* <th>ค่าอาหาร</th>
                          <th>ค่าส่ง</th> */}
                          <th>ประเภท</th>
                          <th>วันที่</th>
                          {/* <th>สถานะ</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={value.checked || ""}
                                  onChange={(e) => {
                                    let tmp = [...data];
                                    data.forEach((vl, idx) => {
                                      if (vl.id === value.id) {
                                        tmp[idx].checked = !tmp[idx].checked;
                                        setdata(tmp);
                                      }
                                    });
                                  }}
                                />
                              </td>
                              <td>
                                <Link
                                  to={`/${
                                    value.service === "food"
                                      ? "order"
                                      : value.service === "car"
                                      ? "car_service_order"
                                      : "service_fee_order"
                                  }/detail/${value.id}`}
                                >
                                  {value.order_no}
                                </Link>
                              </td>
                              <td>
                                {value.service === "food"
                                  ? "อาหาร"
                                  : value.service === "car"
                                  ? "เรียกรถ"
                                  : "บริการ"}
                              </td>
                              {/* <td>฿{value.food_cost}</td>
                              <td>฿{value.shipping_cost}</td> */}
                              <td>
                                {moment(value.created_at).format("DD/MM/YYYY")}
                              </td>
                              {/* <td>
                                {(() => {
                                  if (value.type === 0)
                                    return "ออเดอร์ล่วงหน้า";

                                  switch (value.status) {
                                    case 0:
                                      return "ยกเลิก";
                                    case 1:
                                      return "ออเดอร์ใหม่";
                                    case 4:
                                      return "กำลังจัดส่ง";
                                    case 5:
                                      return "ส่งสำเร็จ";
                                    case 6:
                                      return "ส่งไม่สำเร็จ";

                                    default:
                                      break;
                                  }
                                })()}
                              </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div style={{ float: "right" }}>
                      <ReactPaginate
                        previousLabel={"← Previous"}
                        nextLabel={"Next →"}
                        breakLabel={<span className="gap">...</span>}
                        pageCount={page_count}
                        onPageChange={(e) => setcurrent_page(e.selected + 1)}
                        forcePage={current_page - 1}
                        containerClassName={"pagination"}
                        previousLinkClassName={"previous_page"}
                        nextLinkClassName={"next_page"}
                        disabledClassName={"disabled"}
                        activeClassName={"active"}
                      />
                    </div>
                    <br />
                  </div>
                </LoadingMask>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
