import React, { useState } from "react";
import { Switch, Redirect } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { NotiContext, RulesContext } from "./store/keep";

import { NotFound } from "./NotFound";
import { Topbar } from "./Components/Topbar";
import { Sidebar } from "./Components/Sidebar";
import { Dashboard } from "./Components/Dashboard";
import { RiderAll } from "./Components/Rider/RiderAll";
import { RestaurantApprove } from "./Components/Restaurant/RestaurantApprove";
import { RestaurantAll } from "./Components/Restaurant/RestaurantAll";
import { RestaurantEdit } from "./Components/Restaurant/RestaurantEdit";
import { RestaurantDetail } from "./Components/Restaurant/RestaurantDetail";
import { RestaurantDetailMenuAdd } from "./Components/Restaurant/RestaurantDetailMenuAdd";
import { OrderIndex } from "./Components/Order/OrderIndex";
import { SendingIndex } from "./Components/Sending/SendingIndex";
import { SaleBillIndex } from "./Components/SaleBill/SaleBillIndex";
import { SettingIndex } from "./Components/Setting/SettingIndex";
import { RestaurantDetailMenuEdit } from "./Components/Restaurant/RestaurantDetailMenuEdit";
import { RiderAdd } from "./Components/Rider/RiderAdd";
import { RiderTeam } from "./Components/Rider/RiderTeam";
import { RiderDetailHistory } from "./Components/Rider/RiderDetailHistory";
import { RiderDetailCredit } from "./Components/Rider/RiderDetailCredit";
import { RiderDetailWithdraw } from "./Components/Rider/RiderDetailWithdraw";
import { RiderDetailService } from "./Components/Rider/RiderDetailService";
import { RiderEdit } from "./Components/Rider/RiderEdit";
import { RestaurantAdd } from "./Components/Restaurant/RestaurantAdd";
import { OrderDetail } from "./Components/Order/OrderDetail";
import { RiderApprove } from "./Components/Rider/RiderApprove";
import { NotificationFirebase } from "./store/NotificationFirebase";
import { RecommenedHead } from "./Components/Recommened/RecommenedHead";
import { PromotionBannerHead } from "./Components/PromotionBanner/PromotionBannerHead";
import { RestaurantPromotionHead } from "./Components/RestaurantPromotion/RestaurantPromotionHead";
import { AdminProfile } from "./Components/AdminProfile";
import { RiderImport } from "./Components/Rider/RiderImport";
import { RestaurantImport } from "./Components/Restaurant/RestaurantImport";
import { OrderCarDetail } from "./Components/OrderCarService/OrderCarDetail";
import { OrderCarIndex } from "./Components/OrderCarService/OrderCarIndex";
import { SaleBillShippingIndex } from "./Components/SaleBill/SaleBillShippingIndex";

export const Admin = ({ token: token }) => {
  const [rules, setrules] = useState({
    banner: false,
    deposit: false,
    member: false,
    news: false,
    order: false,
    promotion: false,
    recommend: false,
    restaurant: false,
    rider: false,
    setting: false,
    shipping: false,
    summary: false,
    withdraw: false,
    branch: false,
  });

  const [noti, setnoti] = useState({
    rider: 0,
    restuarant: 0,
    order: 0,
    withdraw: 0,
    credit: 0,
    getdata: "",
  });

  // useEffect(() => {
  //   console.log('rules',rules);
  // }, [rules]);

  return (
    <RulesContext.Provider value={{ rules, setrules }}>
      <NotiContext.Provider value={{ noti, setnoti }}>
        <div className="container-scroller">
          <Topbar />
          <div className="container-fluid page-body-wrapper">
            <Sidebar />
            <div className="main-panel">
              <div className="content-wrapper">
                <NotificationFirebase />

                <Switch>
                  {/* -------------------------------------- Restaurant --------------------------------------  */}

                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id/menu-add"
                    component={RestaurantDetailMenuAdd}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id/menu-edit/:menu_id"
                    component={RestaurantDetailMenuEdit}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/detail/:id"
                    component={RestaurantDetail}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant/edit/:id"
                    component={RestaurantEdit}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/restaurant/approve`}
                    component={RestaurantApprove}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/restaurant/add`}
                    component={RestaurantAdd}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    path="/restaurant/import"
                    component={RestaurantImport}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    path="/restaurant"
                    component={RestaurantAll}
                    isAllowed={true}
                  />

                  {/* -------------------------------------- Rider --------------------------------------  */}
                  <PrivateRoute
                    token={token}
                    path={`/rider/import`}
                    component={RiderImport}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    path={`/rider/edit/:id`}
                    component={RiderEdit}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/history/:id`}
                    component={RiderDetailHistory}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/service/:id`}
                    component={RiderDetailService}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/withdraw/:id`}
                    component={RiderDetailWithdraw}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path={`/rider/credit/:id`}
                    component={RiderDetailCredit}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/team`}
                    component={RiderTeam}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/approve`}
                    component={RiderApprove}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider/add`}
                    component={RiderAdd}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    exact
                    path={`/rider`}
                    component={RiderAll}
                    isAllowed={true}
                  />
                  {/* -------------------------------------- Order -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/order/detail/:id"
                    component={OrderDetail}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="/order"
                    component={OrderIndex}
                    isAllowed={true}
                  />

                  {/* -------------------------------------- Car Service Order -------------------------------------- */}
                  <PrivateRoute
                    token={token}
                    exact
                    path="/car_service_order/detail/:id"
                    component={OrderCarDetail}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    path="/car_service_order"
                    component={OrderCarIndex}
                    isAllowed={true}
                  />

                  {/* -------------------------------------- Sending -------------------------------------- */}
                  {/* <PrivateRoute
                    token={token}
                    path="/sending"
                    component={SendingIndex}
                    isAllowed={true}
                  /> */}

                  {/* --------------------------------------  Others  -------------------------------------- */}

                  <PrivateRoute
                    token={token}
                    path="/setting"
                    component={SettingIndex}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    path="/recommened"
                    component={RecommenedHead}
                    isAllowed={true}
                  />
                  
                  <PrivateRoute
                    token={token}
                    path="/promotion_banner"
                    component={PromotionBannerHead}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="/restaurant_promotion"
                    component={RestaurantPromotionHead}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="/sale_bill"
                    component={SaleBillIndex}
                    isAllowed={true}
                    exact
                  />

                  <PrivateRoute
                    token={token}
                    path="/sale_bill/shipping"
                    component={SaleBillShippingIndex}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    path="/admin_profile"
                    component={AdminProfile}
                    isAllowed={true}
                  />

                  <PrivateRoute
                    token={token}
                    exact
                    path="/"
                    component={Dashboard}
                    isAllowed={true}
                  />
                  <PrivateRoute
                    token={token}
                    path="*"
                    component={NotFound}
                    isAllowed={true}
                  />
                  <Redirect to="/404" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </NotiContext.Provider>
    </RulesContext.Provider>
  );
};
